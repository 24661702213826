/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React, { Suspense, useCallback, useEffect, useMemo, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useLocation,
} from 'react-router-dom'
import useHotjar from 'react-use-hotjar'
import moment from 'moment-timezone'
import { isEqual } from 'lodash'

import {
  MutationPostCreateArgs,
  MutationPostCreateRequest,
  MutationPostUpdateArgs,
  MutationPostUpdateRequest,
  MutationPostUpdateScheduledArgs,
  QueryEventsRequest,
  QueryTimezonesRequest,
  mutationPostCreate,
  mutationPostUpdate,
  mutationPostUpdateScheduled,
  queryActiveFeatures,
  queryEvents,
  queryTimezones,
  queryUser,
} from '@sportsyou/api'
import {
  PostComposer,
  PostComposerInitialView,
  useDialog,
} from '@sportsyou/react-dom-ui'
import {
  BrowserNotificationOptions,
  useBrowserNotifications,
  useFetchApi,
  useNotificationSubscription,
  usePrevious,
} from '@sportsyou/react-hooks'
import { Colors, sleep } from '@sportsyou/core'

import { ContentContainer } from '../styles/global-styles'
import { ChatCreateModal, Lightbox } from 'web/components'
import { WEB_SOCKET, publicImagesPath } from 'web/constants'
import { useAnalytics } from 'web/hooks'
import { AuthLayout, BlankLayout, PageLayout } from 'web/layouts'
import { RootState } from 'web/store/rootReducer'
import {
  addAlert,
  addRequest,
  clearUser,
  fetchChatableUsers,
  fetchPages,
  fetchFriends,
  fetchTeammates,
  fetchTeamsWithSort,
  hideChatCreateModal,
  hideLightbox,
  hidePostComposer,
  resetPostComposer,
  selectAllTeamsAndGroups,
  selectCurrentUser,
  selectTiemzones,
  setRefreshScheduledPostFeed,
  setShouldNavigateToLogin,
  setTimezones,
  setUserLoggedIn,
  updateUser,
  setAgendaEvents,
} from 'web/store/slices'
import {
  ExtendedNotification,
  generateBrowserNotificationMessage,
} from 'web/utils/GenerateBrowserNotificationMessage'
import { environment } from '../environments/environment'
import useSystemStatus from './hooks/useSystemStatus'
import useVersions from './hooks/useVersions'

/**
 * START: Lazy Imports
 */

// Auth Pages
const LoginPage = React.lazy(() => import('./pages/Auth/Login'))
const SignupPage = React.lazy(() => import('./pages/Auth/Signup'))
const SignupFlowPage = React.lazy(() => import('./pages/Auth/NewUser'))
const SetNewPassword = React.lazy(() => import('./pages/Auth/SetNewPassword'))

// Calendar Pages
const CalendarDetailPage = React.lazy(() => import('./pages/Calendar/Detail'))
const CalendarSubscribePage = React.lazy(
  () => import('./pages/Calendar/Subscribe')
)
const CalendarEditPage = React.lazy(() => import('./pages/Calendar/Edit'))
const CalendarIndexPage = React.lazy(() => import('./pages/Calendar'))
const CalendarNewPage = React.lazy(() => import('./pages/Calendar/New'))

// Pages
const PagesIndexPage = React.lazy(() => import('./pages/Pages'))
const PagesProfile = React.lazy(() => import('./pages/Pages/Profile'))

// Team Profile
const TeamProfilePage = React.lazy(() => import('./pages/Teams/Profile'))
const TeamCreateMultiplePage = React.lazy(
  () => import('./pages/Teams/CreateMultiple')
)
const TeamsIndexPage = React.lazy(() => import('./pages/Teams'))

const ChatPage = React.lazy(() => import('./pages/Chat'))
const ContactsPage = React.lazy(() => import('./pages/Contacts'))
const FeedPage = React.lazy(() => import('./pages/Feed'))
const FoldersPage = React.lazy(() => import('./pages/Folders'))
const MediaPage = React.lazy(() => import('./pages/Media'))
const PostPage = React.lazy(() => import('./pages/Post'))
const ProfilePage = React.lazy(() => import('./pages/User'))

// Account Settings and Notifications Pages
const SettingsAccountPage = React.lazy(() => import('./pages/Settings/Account'))
const SettingsNotificationsPage = React.lazy(
  () => import('./pages/Settings/Notifications')
)

const JoinPage = React.lazy(() => import('./pages/Join'))

// Error Pages
const NotFoundPage = React.lazy(() => import('./pages/Error/NotFoundPage'))

const PlaybookPage = React.lazy(() => import('./pages/Playbook'))

/**
 * END: Lazy Imports
 */

const SuspenseFallback = () => <ContentContainer></ContentContainer>

const Page = (Component: JSX.Element) => (
  <Suspense fallback={SuspenseFallback()}>{Component}</Suspense>
)

/**
 * Component to scroll page to top when pathname changes
 * @returns null
 */
const ScrollToTop = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return null
}

export function App() {
  const { ask, send: sendBrowserNotification } = useBrowserNotifications()
  const { checkForVersionChange } = useVersions()
  const { i18n } = useTranslation()
  const { status: systemStatus } = useSystemStatus()
  const { initHotjar } = useHotjar()
  const { initialize, setUserProperties } = useAnalytics()
  const { sendBanner } = useDialog()
  const dispatch = useDispatch()
  const notiHook = useNotificationSubscription()

  const loginInit = useRef(false)
  const isMounted = useRef(false)

  const { fetch: createPost } = useFetchApi(mutationPostCreate)
  const { fetch: getEvents } = useFetchApi(queryEvents)
  const { fetch: getUserInfo } = useFetchApi(queryUser)
  const { fetch: getFeatures } = useFetchApi(queryActiveFeatures)
  const { fetch: updatePost } = useFetchApi(mutationPostUpdate)
  const { fetch: updateScheduledPost } = useFetchApi(
    mutationPostUpdateScheduled
  )
  const { fetch: getTimezones } = useFetchApi(queryTimezones)

  const { isUserLoggedIn, settings } = useSelector(
    (state: RootState) => state.user
  )
  const {
    isOpen: isChatCreateModalOpen,
    message: chatCreateModalMessage,
    showTeamPicker: chatCreateModalShowTeamPicker,
    teamId: chatCreateModalTeamId,
    title: chatCreateModalTitle,
    uploads: chatCreateModalUploads,
  } = useSelector((state: RootState) => state.chatCreateModal)
  const { allTeamsAndGroups } = useSelector(
    (state: RootState) => state.teams,
    isEqual
  )
  const currentUser = useSelector(selectCurrentUser)
  const {
    hidePostTypeButtons: postComposerHidePostTypeButtons,
    initialView: postComposerInitialView,
    isOpen: isPostComposerOpen,
    post: postComposerPost,
    uploads: postComposerUploads,
  } = useSelector((state: RootState) => state.postComposer)
  const timezones = useSelector(selectTiemzones)

  const prevIsUserLoggedIn = usePrevious(isUserLoggedIn, undefined)

  const teamsAndGroupsTheUserCanPostTo = useMemo(() => {
    return allTeamsAndGroups.filter((teamOrGroup) => !!teamOrGroup.canPost)
  }, [allTeamsAndGroups])

  const onClickBrowserNotification = useCallback((url: string) => {
    window.location.href = url
  }, [])

  const initializeAnalytics = useCallback(async () => {
    if (isUserLoggedIn === prevIsUserLoggedIn) return

    const diff = !settings.userUpdatedAt
      ? 0
      : new Date().getTime() - new Date(settings.userUpdatedAt).getTime()
    const shouldUpdateUserBeforeInitializeAnalytics = diff > 1 * 1_000
    if (isUserLoggedIn && shouldUpdateUserBeforeInitializeAnalytics) {
      const { data: userInfo, ok } = await getUserInfo()
      if (ok && userInfo) {
        dispatch(updateUser(userInfo))
        setUserProperties()
        // toggle 'isUserLoggedIn' to cause re-firing of this useEffect
        dispatch(setUserLoggedIn(false))
        await sleep(1)
        dispatch(setUserLoggedIn(true))
      }
    } else {
      initialize()
    }

    isMounted.current = true
    loginInit.current = true
  }, [isUserLoggedIn])

  const initializeSubscription = useCallback(async () => {
    if (isUserLoggedIn === prevIsUserLoggedIn || !isUserLoggedIn) return

    notiHook.start()
  }, [isUserLoggedIn])

  const loginInitializerWithDelay = useCallback(
    async (onMount: boolean) => {
      await sleep(WEB_SOCKET.delay.subscription.notification)

      if (!onMount && (!isMounted.current || loginInit.current)) return

      initializeAnalytics()
      initializeSubscription()
    },
    [initializeAnalytics, initializeSubscription]
  )

  useEffect(() => {
    // if the request came from v1 and the user isn't logged in,
    // log them in from the v1 cookie
    const checkUser = async () => {
      const { data: _user, ok } = await getUserInfo()
      if (ok && _user) {
        if (!isUserLoggedIn || !currentUser.id) {
          const { data: _features } = await getFeatures()
          localStorage.setItem('user-features', JSON.stringify(_features))
          dispatch(updateUser(_user))
          dispatch(setUserLoggedIn(true))
        }
      } else {
        dispatch(clearUser())
        if (window.location.pathname !== '/login') {
          dispatch(setShouldNavigateToLogin(true))
        }
      }
    }

    async function getTimezoneInfo() {
      moment.tz.setDefault(moment.tz.guess(true))
      const { data: timezoneData, ok } = await getTimezones({
        defaultCode: moment.tz.guess(),
      } as QueryTimezonesRequest)
      if (ok && timezoneData?.length) {
        if (!isEqual(timezoneData, timezones)) {
          dispatch(setTimezones(timezoneData))
        }
      }
    }

    getTimezoneInfo()
    checkUser()
    ask()

    loginInitializerWithDelay(true)

    setTimeout(() => {
      if (!isMounted.current) {
        isMounted.current = true
      }
    }, (WEB_SOCKET.delay.subscription.notification ?? 0) + 500)
  }, [])

  useEffect(() => {
    const hjid: number = parseInt(environment.hotJarId ?? '')
    const hjsv: number = parseInt(environment.hotJarSv ?? '')
    hjid && hjsv && initHotjar(hjid, hjsv, false)
  }, [initHotjar])

  useEffect(() => {
    if (isMounted.current && isUserLoggedIn === false) {
      loginInit.current = false
    }

    if (
      isMounted.current &&
      !loginInit.current &&
      isUserLoggedIn !== prevIsUserLoggedIn &&
      isUserLoggedIn
    ) {
      loginInitializerWithDelay(false)
    }
  }, [isUserLoggedIn])

  useEffect(() => {
    if (!(currentUser && notiHook.notification)) return

    const noti = notiHook.notification

    if (noti.deletedAt) return

    // check if notification is a request
    let isRequest = false
    if (noti.type === 'team' && noti.message?.includes('Added you to')) {
      isRequest = true
    } else if (
      noti.type === 'friend' &&
      noti.message?.includes('Made a friend request')
    ) {
      isRequest = true
    }
    dispatch(isRequest ? addRequest(noti) : addAlert(noti))

    console.log({ noti })
    const _noti: ExtendedNotification = {
      ...noti,
      isAccepted: noti.friendRequestStatus === 'accepted',
      isRejected: noti.friendRequestStatus === 'rejected',
    }

    // noti.isAccepted = noti.friendRequestStatus === 'accepted'
    // noti.isRejected = noti.friendRequestStatus === 'rejected'

    if (_noti.type === 'friend-accept') {
      dispatch(fetchFriends())
    }

    const { message, url } = generateBrowserNotificationMessage(
      _noti,
      currentUser.id!
    )
    console.log({ message, url })
    // let params: BrowserNotificationOptions = {
    // }
    // if (message) {
    //   params.body = message
    // }
    // if (url) {
    //   params.onClick = () => onClickBrowserNotification(url)
    // }
    if (message) {
      sendBrowserNotification({
        body: message,
        closeOnClick: !!url,
        icon: `${publicImagesPath}/favicon.png`,
        onClick: url ? () => () => onClickBrowserNotification(url) : undefined,
        title: 'sportsYou',
      } as BrowserNotificationOptions)
    }
  }, [currentUser, notiHook.notification])

  // Fetch agenda events (for 1 week)
  const fetchEvents = useCallback(async () => {
    const { data } = await getEvents({
      startDate: moment().startOf('day').toISOString(),
      endDate: moment().add(1, 'week').endOf('day').toISOString(),
      summaryOnly: true,
      teamIds: (allTeamsAndGroups.map((t) => t.id) ?? []).concat(['-1']),
    } as QueryEventsRequest)
    if (data) {
      const sortedEvents = data.sort((a, b) =>
        a.startDate && b.startDate
          ? moment(a.startDate).diff(moment(b.startDate))
          : 0
      )
      dispatch(setAgendaEvents(sortedEvents))
    }
  }, [allTeamsAndGroups])

  const fetchData = useCallback(async () => {
    dispatch(fetchChatableUsers())
    dispatch(fetchPages())
    dispatch(fetchTeamsWithSort())
    dispatch(fetchFriends())
    dispatch(fetchTeammates())
    fetchEvents()
  }, [dispatch])

  const onChatCreateModalClose = useCallback(() => {
    dispatch(hideChatCreateModal())
  }, [dispatch])

  const onPostComposerClose = useCallback(() => {
    dispatch(hidePostComposer())
  }, [dispatch])

  const onCreatePost = useCallback(
    async (post: MutationPostCreateArgs) => {
      console.log('onCreatePost', { post })
      const { targetIds: ids } = post
      const _post = post
      _post.postTypes = ids.map((id) =>
        id === currentUser.id! ? 'user' : 'team'
      )

      const scheduledTime: any = _post.scheduledTime
      if (scheduledTime) {
        if (scheduledTime instanceof Date) {
          _post.scheduledTime = scheduledTime.toISOString()
        }
      }

      const { data, error, ok } = await createPost({
        ..._post,
      } as MutationPostCreateRequest)

      if (data && ok) {
        sendBanner({
          autoDismiss: true,
          dismissTime: 6000,
          status: 'success',
          message: 'Post created',
        })
        dispatch(resetPostComposer())
      }
      if (error) {
        console.log({ error })
        sendBanner({
          autoDismiss: true,
          message:
            'There was an error creating a new post. Please try again later or contact support',
          status: 'danger',
        })
      }
    },
    [createPost, currentUser.id, onPostComposerClose, sendBanner]
  )

  const onUpdatePost = useCallback(
    async (post: MutationPostUpdateArgs) => {
      const { data, error, ok } = await updatePost({
        ...post,
      } as MutationPostUpdateRequest)
      if (data && ok) {
        sendBanner({
          autoDismiss: true,
          dismissTime: 6000,
          status: 'success',
          message: 'Post updated',
        })
        onPostComposerClose()
      }
      if (error) {
        console.log({ error })
        sendBanner({
          autoDismiss: true,
          message:
            'There was an error updating the post. Please try again later or contact support',
          status: 'danger',
        })
      }
    },
    [onPostComposerClose, sendBanner, updatePost]
  )

  const onUpdateScheduledPost = useCallback(
    async (post: MutationPostUpdateScheduledArgs) => {
      const _post = post
      _post.targetIds = _post.targetIds.filter((id) => id !== currentUser.id!)
      _post.postTypes = _post.targetIds.map((id) =>
        id === currentUser.id ? 'user' : 'team'
      )

      console.log({ _post })

      const scheduledTime: any = _post.scheduledTime
      if (scheduledTime) {
        if (scheduledTime instanceof Date) {
          _post.scheduledTime = scheduledTime.toISOString()
        }
      }

      const { data, error, ok } = await updateScheduledPost({
        ..._post,
      } as MutationPostUpdateScheduledArgs)

      if (data && ok) {
        dispatch(setRefreshScheduledPostFeed())
        sendBanner({
          autoDismiss: true,
          dismissTime: 6000,
          status: 'success',
          message: 'Post updated',
        })
        onPostComposerClose()
        dispatch(resetPostComposer())
      }
      if (error) {
        console.log({ error })
        sendBanner({
          autoDismiss: true,
          message:
            'There was an error. Please try again later or contact support',
          status: 'danger',
        })
      }
    },
    [currentUser.id, onPostComposerClose, sendBanner, updateScheduledPost]
  )

  useEffect(() => {
    currentUser?.id && fetchData()
  }, [currentUser?.id])

  useEffect(() => {
    if (systemStatus?.OK === false && systemStatus?.message) {
      sendBanner({
        autoDismiss: false,
        message: systemStatus?.message,
        status: 'alert',
        actions: systemStatus?.url
          ? [
              {
                label: 'Learn more',
                onClick: () => {
                  window.open(systemStatus?.url, '_blank')
                },
              },
            ]
          : [],
      })
    }
  }, [sendBanner, systemStatus?.OK, systemStatus?.message, systemStatus?.url])

  /**
   * Helpers for setting routes
   */
  const teamGroupsRoutes = (typePlural: 'teams' | 'groups') => {
    const typeSingular = typePlural.substring(0, typePlural.length - 1) as
      | 'group'
      | 'team'
    const routes = {
      // [`/${type}/create/`]: TeamProfilePage, // TODO
      [`/${typePlural}/create/multiple`]: TeamCreateMultiplePage,
      [`/${typePlural}/:id/:tab/:mediaType`]: TeamProfilePage,
      [`/${typePlural}/:id/:tab/:mediaType/:albumId`]: TeamProfilePage,
      [`/${typePlural}/:id/:tab`]: TeamProfilePage,
      [`/${typePlural}/:id/:tab/:mode/:date/:eventId`]: TeamProfilePage, // calendar view with event popover
      [`/${typePlural}/:id/edit`]: TeamProfilePage,
      [`/${typePlural}/:id/settings`]: TeamProfilePage,
      [`/${typePlural}/:id`]: TeamProfilePage,
      [`/${typePlural}`]: TeamsIndexPage,

      [`/${typeSingular}/${typeSingular}Post.html`]: PostPage, // web v1 compatibility ... ?id={{teamId}}&postId={{postId}} --> team post detail
      [`/${typeSingular}/${typeSingular}Posts.html`]: PostPage, // web v1 compatibility ... ?id={{teamId}}&postId={{postId}} --> team post detail
      [`/${typeSingular}/edit.html`]: TeamProfilePage, // web v1 compatibility
      [`/${typeSingular}/join.html`]: LoginPage, // web v1 compatibility ... token, id, addr
      [`/${typeSingular}`]: TeamProfilePage, // web v1 compatibility
      '/join.html': TeamProfilePage, // web v1 compatibility ... token, id, addr
    }

    return Object.entries(routes).map(([path, Component]) => (
      <Route
        key={path}
        path={path}
        element={Page(<Component type={typeSingular} />)}
      />
    ))
  }
  // web v1 compatibility
  const teamGroupsRoutesForV1 = (typePlural: 'teams' | 'groups') => {
    const typeSingular = typePlural.substring(0, typePlural.length - 1) as
      | 'group'
      | 'team'
    const routes = {
      [`/${typeSingular}/album.html`]: (
        <TeamProfilePage tab='media' type={typeSingular} />
      ),
      [`/${typeSingular}/calendar.html`]: (
        <TeamProfilePage tab='calendar' type={typeSingular} />
      ),
      [`/${typeSingular}/media.html`]: (
        <TeamProfilePage tab='media' type={typeSingular} />
      ),
      [`/${typeSingular}/team.html`]: (
        <TeamProfilePage tab='members' type={typeSingular} />
      ),
      [`/${typeSingular}/group.html`]: (
        <TeamProfilePage tab='members' type={typeSingular} />
      ),
    }

    return Object.entries(routes).map(([path, Component]) => (
      <Route key={path} path={path} element={Page(Component)} />
    ))
  }
  const profileRoutes = () => {
    const routes = {
      '/profile': <ProfilePage />,
      '/profile/:id': <ProfilePage />,
      '/profile/:id/calendar': <ProfilePage tab='calendar' />,
      '/profile/:id/contacts': <ProfilePage tab='contacts' />,
      '/profile/:id/feed': <ProfilePage tab='feed' />,
      '/profile/:id/media': <ProfilePage tab='media' />,
      '/profile/:id/media/:mediaType': <ProfilePage tab='media' />,
      '/profile/:id/media/:mediaType/:albumId': <ProfilePage tab='media' />,
      '/profile/media': <ProfilePage tab='media' />,
      '/profile/media/:mediaType': <ProfilePage tab='media' />,
      '/profile/media/:mediaType/:albumId': <ProfilePage tab='media' />,

      '/profile/album.html': <ProfilePage tab='media' />, // web v1 compatibility
      '/profile/friend.html': <ProfilePage tab='feed' />, // web v1 compatibility ... confirm request: token, id, addr
      '/profile/friendReject.html': <ProfilePage tab='feed' />, // web v1 compatibility ... reject request: token, id, addr
      '/profile/friends.html': <ProfilePage tab='contacts' />, // web v1 compatibility
      '/profile/media.html': <ProfilePage tab='media' />, // web v1 compatibility
      // TODO: '/profile/edit/info.html': ???, // web v1 compatibility
    }
    return Object.entries(routes).map(([path, Component]) => (
      <Route key={path} path={path} element={Page(Component)} />
    ))
  }
  const pagesFeedAndProfileRoutes = () => {
    const routes = {
      '/pages': <PagesIndexPage />,
      '/pages/:friendly_url': <PagesProfile tab='feed' />,
      '/pages/:friendly_url/feed': <PagesProfile tab='feed' />,
      '/pages/:friendly_url/:tab/:mediaType': <PagesProfile tab='media' />,
      '/pages/:friendly_url/:tab/:mediaType/:albumId': (
        <PagesProfile tab='media' />
      ),
    }
    return Object.entries(routes).map(([path, Component]) => (
      <Route key={path} path={path} element={Page(Component)} />
    ))
  }

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate='%s'
        defaultTitle='sportsYou'
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta
          name='description'
          content='The Ultimate FREE Team Communication Platform'
        />
        {environment.production ? (
          <meta name='robots' content='index, follow' />
        ) : (
          <meta name='robots' content='noindex, nofollow' />
        )}
        <link
          href={`https://sportsyou.com${window.location.pathname}`}
          rel='canonical'
        />
        {/* <script
          src={`https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsKey}&libraries=places`}
        ></script> */}
      </Helmet>

      <ScrollToTop />

      {/* START: routes */}
      <Routes>
        {/* New user sign up flow */}
        <Route element={<BlankLayout />}>
          {isUserLoggedIn ? (
            <Route path='/index.html' element={<Navigate to={'/home'} />} />
          ) : (
            <Route
              path='/index.html'
              Component={() => {
                window.location.href = environment.urls.web
                return null
              }}
            />
          )}
          <Route path='/signup/new' element={Page(<SignupFlowPage />)} />
          <Route path='/signup/confirm' element={Page(<SignupFlowPage />)} />
          <Route path='/signup/google.html' element={Page(<LoginPage />)} />
          {/* web v1 compatibility */}
          <Route
            path='/signup/confirm.html'
            element={Page(<SignupFlowPage />)}
          />

          <Route path='/playbook' element={Page(<PlaybookPage />)} />
        </Route>

        <Route element={<AuthLayout />}>
          <Route path='/' element={Page(<LoginPage />)} />
          <Route path='/login' element={Page(<LoginPage />)} />
          <Route path='/logout' element={Page(<LoginPage logout />)} />
          <Route path='/signup' element={Page(<SignupPage />)} />

          <Route path='/set-password' element={Page(<SetNewPassword />)} />

          <Route path='/unsubscribe' element={Page(<LoginPage />)} />

          {/* web v1 compatibility */}
          <Route
            path='/resetPassword/reset.html'
            element={Page(<SetNewPassword />)}
          />
          <Route path='/verification.html' element={Page(<LoginPage />)} />

          {/* qr code join link */}
          <Route path='/join/' element={Page(<JoinPage />)} />
        </Route>

        {/* Calendar Routes */}
        <Route element={<PageLayout fullscreen stretchContent />}>
          <Route path='/calendar' element={Page(<CalendarIndexPage />)} />
          <Route
            path='/calendar/:mode/:date'
            element={Page(<CalendarIndexPage />)}
          />
          <Route
            path='/calendar/:mode/:date/:eventId'
            element={Page(<CalendarIndexPage />)} // calendar view with event popover
          />
          <Route path='/calendar/new' element={Page(<CalendarNewPage />)} />
          <Route
            path='/calendar/:id/edit'
            element={Page(<CalendarEditPage />)}
          />

          <Route /* web v1 compatibility  */
            path='/calendar/setAttending.html'
            element={Page(<CalendarIndexPage />)}
          />
        </Route>

        <Route element={<PageLayout />}>
          {pagesFeedAndProfileRoutes()}
          {profileRoutes()}
          {teamGroupsRoutes('groups')}
          {teamGroupsRoutes('teams')}
          {teamGroupsRoutesForV1('groups')}
          {teamGroupsRoutesForV1('teams')}
          <Route path='/home' element={Page(<FeedPage />)} />
          <Route
            /* web v1 compatibility */
            path='/home.html'
            element={Page(<FeedPage />)}
          />
          <Route path='/chat' element={Page(<ChatPage />)} />
          <Route path='/media' element={Page(<MediaPage type='photo' />)} />
          <Route
            path='/media/photos'
            element={Page(<MediaPage type='photo' />)}
          />
          <Route
            path='/media/videos'
            element={Page(<MediaPage type='video' />)}
          />
          <Route
            path='/media/albums'
            element={Page(<MediaPage type='album' />)}
          />
          <Route
            path='/media/albums/:albumId'
            element={Page(<MediaPage type='album' />)}
          />
          <Route path='/post/:id' element={Page(<PostPage />)} />
          <Route path='/folders' element={Page(<FoldersPage />)} />
          <Route path='/contacts' element={Page(<ContactsPage />)} />
          <Route path='/calendar/:id' element={Page(<CalendarDetailPage />)} />
          <Route
            path='/calendar/subscribe'
            element={Page(<CalendarSubscribePage />)}
          />
          <Route path='/settings' element={Page(<SettingsAccountPage />)} />
          <Route
            path='/settings/notifications'
            element={Page(<SettingsNotificationsPage />)}
          />
          <Route /* web v1 compatibility */
            path='/calendar/settings.html'
            element={Page(<CalendarSubscribePage />)}
          />
          <Route /* web v1 compatibility */
            path='/settings/account.html'
            element={Page(<SettingsAccountPage />)}
          />
          <Route /* web v1 compatibility */
            path='/settings/alerts.html'
            element={Page(<SettingsNotificationsPage />)}
          />
          <Route /* web v1 compatibility */
            path='/settings/email.html'
            element={Page(<SettingsNotificationsPage />)}
          />
          <Route /* web v1 compatibility */
            path='/settings/team.html'
            element={Page(<SettingsNotificationsPage />)}
          />
        </Route>

        <Route
          /* sy video compatibility */
          path='/video'
          element={Page(<LoginPage />)}
        />

        <Route path='/*' element={Page(<NotFoundPage />)} />
      </Routes>
      {/* END: routes */}

      <Lightbox />
      <ChatCreateModal
        isOpen={isChatCreateModalOpen}
        message={chatCreateModalMessage}
        onClose={onChatCreateModalClose}
        showTeamPicker={chatCreateModalShowTeamPicker}
        teamId={chatCreateModalTeamId}
        title={chatCreateModalTitle}
        uploads={chatCreateModalUploads}
      />
      <PostComposer
        currentUser={currentUser}
        hidePostTypeButtons={postComposerHidePostTypeButtons}
        initialPost={postComposerPost}
        initialUploads={postComposerUploads}
        initialView={postComposerInitialView as PostComposerInitialView}
        isModalVisible={isPostComposerOpen}
        onClose={onPostComposerClose}
        onCreatePost={onCreatePost}
        onUpdatePost={onUpdatePost}
        onUpdateScheduledPost={onUpdateScheduledPost}
        teams={teamsAndGroupsTheUserCanPostTo}
      />
    </BrowserRouter>
  )
}

export default App
