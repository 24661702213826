import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { Avatar, Checkbox, Chevron, Searchbar } from '@sportsyou/react-dom-ui'
import { Colors } from '@sportsyou/core'
import { Friend, Profile, Teammate, TeamMember, User } from '@sportsyou/api'
import CheckboxSimple from '../CheckboxSimple'

export type Person = User | Profile | Friend | TeamMember | Teammate

type Section = {
  title: string
  data: Person[]
}

interface SelectUserListSectionedProps {
  addHoverStyles?: boolean
  canSelectAll?: boolean
  clickableHeader?: boolean
  collapsible?: boolean
  listClassName?: string
  listStyle?: React.CSSProperties
  name?: string
  noResultsText?: string
  onChange: (users: Person[]) => void
  onSearchChange?: (value: string) => void
  onSearchSubmit?: (value: string) => void
  rightAlignCheckbox?: boolean
  rowSize?: 'small' | 'big'
  rowStyle?: React.CSSProperties
  searchable?: boolean
  searchbarContainerStyle?: React.CSSProperties
  searchbarInputContainerStyle?: React.CSSProperties
  searchbarPlaceholder?: string
  searchbarStyle?: React.CSSProperties
  searchbarValue?: string
  selectedUsers?: Person[]
  sections?: Array<Section>
  useCheckboxForSelectAll?: boolean
}

export const SelectUserListSectioned = ({
  addHoverStyles,
  canSelectAll,
  clickableHeader,
  collapsible,
  noResultsText,
  onChange,
  onSearchChange,
  onSearchSubmit,
  listStyle,
  rightAlignCheckbox = false,
  rowSize = 'big',
  rowStyle,
  searchable,
  searchbarContainerStyle,
  searchbarInputContainerStyle,
  searchbarPlaceholder,
  searchbarStyle,
  searchbarValue = '',
  selectedUsers = [],
  sections,
  useCheckboxForSelectAll = false,
}: SelectUserListSectionedProps) => {
  const [expandedSections, setExpandedSections] = useState<Array<number>>([])
  const [searchValue, setSearchValue] = useState<string>(searchbarValue)

  useEffect(() => {
    setSearchValue(searchbarValue)
  }, [searchbarValue])

  const users = useMemo(() => {
    const _users = sections
      ?.map((section) => section.data)
      .reduce((acc, data) => {
        return [...acc, ...data]
      }, [])
    return _users ?? []
  }, [sections])

  const getUserIds = (users: Array<Person>): Array<string> =>
    users.map((user) => ((user as TeamMember).userId ?? user.id) as string)

  const selectedUserIds = useMemo(
    () => getUserIds(selectedUsers),
    [selectedUsers]
  )

  const isUserSelected = useCallback(
    (user: Person): boolean => {
      const user_id = ((user as TeamMember).userId ?? user.id) as string
      return selectedUserIds.includes(user_id)
    },
    [selectedUserIds]
  )

  const areUsersSelected = useCallback(
    (users: Array<Person>): boolean => {
      const userIds = getUserIds(users)
      return selectedUserIds.some((user) => userIds.includes(user))
    },
    [selectedUserIds]
  )

  const areAllUsersSelected = useCallback(
    (users: Array<Person>): boolean => {
      const userIds = getUserIds(users)
      return userIds.every((id) => selectedUserIds.includes(id))
    },
    [selectedUserIds]
  )

  const areSomeUsersInSectionSelected = useCallback(
    (section: { data: Array<Person> }): boolean =>
      areUsersSelected(section.data),
    [areUsersSelected]
  )

  const areAllUsersInSectionSelected = useCallback(
    (section: { data: Array<Person> }): boolean =>
      areAllUsersSelected(section.data),
    [areAllUsersSelected]
  )

  const isSectionExpanded = useCallback(
    (index: number): boolean => expandedSections.includes(index),
    [expandedSections]
  )

  const onSectionToggle = useCallback(
    (index: number) => {
      if (isSectionExpanded(index)) {
        setExpandedSections(expandedSections.filter((i) => i !== index))
      } else {
        setExpandedSections([...expandedSections, index])
      }
    },
    [expandedSections, isSectionExpanded]
  )

  const filteredUsers = useMemo(
    () =>
      searchValue !== ''
        ? users.filter((user) => {
            const name = `${user.firstName ?? ''} ${
              user.lastName ?? ''
            }`.toLowerCase()
            return name.toLowerCase().includes(searchValue)
          })
        : users,
    [searchValue, users]
  )

  const isUserFiltered = useCallback(
    (user: Person): boolean => {
      return filteredUsers.some((filteredUser) => filteredUser.id === user.id)
    },
    [filteredUsers]
  )

  const filteredUsersBySection = useMemo(
    () =>
      sections?.map((section) => {
        return {
          ...section,
          data: section.data.filter((user) => isUserFiltered(user)),
        }
      }),
    [sections, isUserFiltered]
  )

  const onClickUser = useCallback(
    (user: Person) => {
      // get user id to compare with selectedUsers
      const id = getUserIds([user])[0]
      if (isUserSelected(user)) {
        onChange(
          selectedUsers?.filter((selectedUser) => {
            const selectedUserId = getUserIds([selectedUser])[0]
            return id !== selectedUserId
          }) ?? []
        )
      } else {
        onChange([...(selectedUsers ?? []), user])
      }
    },
    [isUserSelected, onChange, selectedUsers]
  )

  const onChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      const trimmedValue = value?.trim() ?? ''
      setSearchValue(trimmedValue)
      onSearchChange?.(trimmedValue)
    },
    [onSearchChange]
  )

  const onKeyPressSearch = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.target as HTMLInputElement
      const trimmedValue = value?.trim() ?? ''
      if (event.key === 'Enter') {
        event.preventDefault()
        onSearchSubmit?.(trimmedValue)
      }
    },
    [onSearchSubmit]
  )

  const handleOnSelectAllBySection = useCallback(
    (section: { data: Array<Person> }, index: number) => {
      // check if all users in section are selected
      const allSelected = areAllUsersInSectionSelected(section)

      if (allSelected) {
        // remove all users from selectedUsers that are in the section
        const usersToRemoveById = getUserIds(section.data)
        onChange(
          selectedUsers?.filter((selectedUser) => {
            // selected user id
            const id = getUserIds([selectedUser])[0]
            return !usersToRemoveById.includes(id)
          }) ?? []
        )
      } else {
        // const usersToAddById = getUserIds(section.data)
        // add users if not already in selectedUsers
        onChange([
          ...(selectedUsers ?? []),
          ...section.data.filter((user) => {
            const id = getUserIds([user])[0]
            return !selectedUserIds.includes(id)
            // return !selectedUsers.includes(user)
          }),
        ])
      }
    },
    [areAllUsersInSectionSelected, onChange, selectedUserIds, selectedUsers]
  )

  const renderRow = (index: number, sectionIndex: number, section: Section) => {
    if (!section) {
      return null
    }
    const user = section.data[index]
    const fullName =
      (user as Exclude<Person, Teammate>).fullName ??
      `${user.firstName} ${user.lastName}`
    return (
      <Row
        $addHoverStyles={addHoverStyles}
        key={`${sectionIndex}-${user.id ?? ''}`}
        onClick={() => onClickUser(user)}
        style={rowStyle}
        $rightAlignCheckbox={rightAlignCheckbox}
      >
        <StyledCheckbox
          checked={isUserSelected(user)}
          boxSize={14}
          title={fullName}
        />
        <UserName>
          <Avatar
            diameter={rowSize === 'small' ? 20 : 36}
            name={fullName}
            uri={user?.profileImage?.[0]?.viewUrl ?? ''}
          />
          <Name>{fullName}</Name>
        </UserName>
      </Row>
    )
  }

  const renderSearchBar = useCallback(() => {
    if (!searchable) {
      return null
    }
    return (
      <Searchbar
        containerStyle={{
          minHeight: 1,
          padding: 10,
          ...searchbarContainerStyle,
        }}
        style={searchbarStyle}
        inputContainerStyle={searchbarInputContainerStyle}
        onChange={onChangeSearch}
        onKeyPress={onKeyPressSearch}
        value={searchValue}
        placeholder={searchbarPlaceholder}
      />
    )
  }, [
    searchable,
    searchbarContainerStyle,
    searchbarStyle,
    searchbarInputContainerStyle,
    onChangeSearch,
    onKeyPressSearch,
    searchValue,
    searchbarPlaceholder,
  ])

  const renderSectionSelectAll = useCallback(
    (section: Section, index: number) => {
      const allSelected = areAllUsersInSectionSelected(section)
      const someSelected = areSomeUsersInSectionSelected(section)

      return useCheckboxForSelectAll ? (
        <SelectAllCheckbox
          checked={allSelected}
          indeterminate={someSelected && !allSelected}
          onChange={() => handleOnSelectAllBySection(section, index)}
        />
      ) : (
        <SelectAllButton
          onClick={() => handleOnSelectAllBySection(section, index)}
        >
          {someSelected ? 'Deselect' : 'Select'} All
        </SelectAllButton>
      )
    },
    [
      areAllUsersInSectionSelected,
      areSomeUsersInSectionSelected,
      handleOnSelectAllBySection,
      useCheckboxForSelectAll,
    ]
  )

  const renderSectionHeader = (index: number) => {
    const section = sections?.[index]
    if (!section) return null
    return (
      <ListHeaderContainer>
        <ListHeader
          $addHoverStyles={addHoverStyles}
          $clickable={!!clickableHeader}
          onClick={clickableHeader ? () => onSectionToggle(index) : undefined}
        >
          <ListHeaderTitle
            $clickable={!clickableHeader}
            onClick={
              !clickableHeader ? () => onSectionToggle(index) : undefined
            }
          >
            {collapsible ? (
              <StyledChevron
                direction={isSectionExpanded(index) ? 'down' : 'right'}
                fill={Colors.DUSTY_GRAY}
                size={30}
              />
            ) : null}
            {section.title} {section.data?.length}
          </ListHeaderTitle>
          {canSelectAll ? renderSectionSelectAll(section, index) : null}
        </ListHeader>
      </ListHeaderContainer>
    )
  }

  const renderList = () => {
    return (
      <div style={listStyle}>
        {filteredUsersBySection?.map((section, sectionIndex) => {
          const isExpanded = collapsible
            ? isSectionExpanded(sectionIndex)
            : true
          return (
            <div key={sectionIndex}>
              {renderSectionHeader(sectionIndex)}
              {isExpanded ? (
                <>
                  {section.data.map((user, index) => {
                    return renderRow(index, sectionIndex, section)
                  })}
                </>
              ) : null}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      {renderSearchBar()}
      {searchValue !== '' && filteredUsers.length === 0 ? (
        <NoResults>
          {noResultsText ?? 'No results to show, please check your spelling.'}
        </NoResults>
      ) : null}
      {renderList()}
    </>
  )
}

const Row = styled.div<{
  $addHoverStyles?: boolean
  $rightAlignCheckbox: boolean
}>`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding-left: 2px;

  ${({ $rightAlignCheckbox }) =>
    $rightAlignCheckbox &&
    `flex-direction: row-reverse; justify-content: space-between; padding-right: 7px;`}

  &:not(:first-child) {
    border-top: 1px solid ${Colors.MYSTIC};
  }

  ${({ $addHoverStyles }) =>
    $addHoverStyles &&
    `
    &:hover {
      background-color: ${Colors.CATSKILL_WHITE};
    }
  `}
`
const UserName = styled.div`
  align-items: center;
  display: flex;
  margin-left: 4px;

  &:hover > span {
    text-decoration: underline;
  }
`

const Name = styled.div`
  margin-left: 6px;
`

const StyledCheckbox = styled(CheckboxSimple)`
  align-items: center;
  justify-content: center;
  margin: 10px 6px 10px 0;
  padding: 2px;
`
const NoResults = styled.p`
  color: ${Colors.SHUTTLE_GRAY};
  padding-left: 10px;
  padding-right: 10px;
`
const ListHeaderContainer = styled.div`
  align-items: center;
  background-color: ${Colors.WHITE};
  border-top: 1px solid ${Colors.ALTO};
  display: flex;
  font-size: 12px;
  justify-content: space-between;
`
const ListHeader = styled.span<{
  $addHoverStyles?: boolean
  $clickable: boolean
}>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
  ${({ $clickable }) =>
    $clickable &&
    `
    cursor: pointer;
    &:hover,
    &:active {
      background-color: ${Colors.CATSKILL_WHITE};
    }
  `}
`
const ListHeaderTitle = styled.span<{ $clickable: boolean }>`
  border-radius: 6px;
  font-weight: 500;
  padding: 4px;
  text-transform: uppercase;
  transition: background-color 120ms ease-in-out;

  ${({ $clickable }) =>
    $clickable &&
    `
    cursor: pointer;
    &:hover,
    &:active {
      background-color: ${Colors.CATSKILL_WHITE};
    }
  `}
`
const StyledChevron = styled(Chevron)`
  margin-right: 8px;
`
const SelectAllCheckbox = styled(Checkbox)`
  margin-right: 6px;
`
const SelectAllButton = styled.span`
  color: ${Colors.PUNCH};
  cursor: pointer;
  padding-right: 12px;
`

export default SelectUserListSectioned
